<template>
  <footer class="footer">
    <div class="footer__top">
      <div class="footer__top__logo-block">
        <img alt="logo" class="footer__top__logo-block__logo" src="@/assets/logo/expanded.svg" @click="goto('/')" />
        <div class="footer__top__logo-block__menu">
          <a class="footer__top__logo-block__menu__item" @click="goto('/patients')">Patients</a>
          <a class="footer__top__logo-block__menu__item" @click="goto('/employers')">Employers</a>
          <a class="footer__top__logo-block__menu__item" @click="goto('/brokers')">Brokers</a>
          <a class="footer__top__logo-block__menu__item" @click="goto('/about')">Who we are</a>
          <a class="footer__top__logo-block__menu__item" @click="goto('/support')">Support</a>
        </div>
      </div>
      <div class="footer__top__download">
        <p class="footer__top__download__title">Available on mobile</p>
        <div class="footer__top__download__btns">
          <a
            class="footer__top__download__btns__link"
            href="https://apps.apple.com/ua/app/axis-clinic/id1562870019"
            target="_blank"
          >
            <img alt="" src="@/assets/landing/appstore.svg" />
          </a>
          <a
            class="footer__top__download__btns__link"
            href="https://play.google.com/store/apps/details?id=clinic.axis.app"
            target="_blank"
          >
            <img alt="" src="@/assets/landing/googleplay.svg" />
          </a>
        </div>
      </div>
      <div class="footer__top__support">
        <p class="footer__top__support__title mb-0">Need Help?</p>
        <div class="footer__top__support__btns">
          <a :href="telContactPhoneNumber" class="footer__top__support__btns__link"> {{ contactPhoneNumber }}</a>
        </div>
      </div>
    </div>
    <v-row class="footer__copyright">
      <div class="footer__copyright__block">
        <span>{{ $t("general.contact.rightsReserved", copyrightParams) }}</span>
        <span class="ml-4">v1.0.0</span>
      </div>
      <div class="footer__copyright__block">
        <router-link class="footer__link" target="_blank" to="/consent">Consent for Treatment</router-link>
        <router-link class="footer__link ml-4" target="_blank" to="/terms">Terms & Conditions</router-link>
      </div>
    </v-row>
  </footer>
</template>

<script>
import { mapState } from "pinia";

import { useAppStore } from "@/pinia-store/app";

export default {
  name: "LandingFooter",
  computed: {
    ...mapState(useAppStore, ["brandName", "contactPhoneNumber", "telContactPhoneNumber"]),
    copyrightParams() {
      return { year: new Date().getFullYear(), brandName: this.brandName };
    },
  },
  methods: {
    goto(path) {
      if (this.$route.path === path) {
        window.scrollTo({ top: 0, behavior: "auto" });
      } else {
        this.$router.push(path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-color: $primary;
  color: #fff;
  padding: 0 40px;

  &__link {
    color: #fff;
    text-decoration: none;
    font-size: 14px;

    @media (max-width: 480px) {
      font-size: 12px;
    }
  }

  &__copyright {
    display: flex;
    justify-content: space-between;
    padding: 24px 0;
    position: relative;

    @media (max-width: 1024px) {
      flex-direction: column;
      align-items: center;
    }

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      border-top: 1px solid #fff;
      opacity: 0.2;
    }

    &__block {
      span {
        font-size: 14px;

        @media (max-width: 480px) {
          font-size: 12px;
        }
      }
    }
  }

  &__top {
    display: flex;
    justify-content: space-between;
    padding: 50px 0;

    @media (max-width: 1170px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }

    &__logo-block {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @media (max-width: 1170px) {
        align-items: center;
      }

      &__logo {
        margin-bottom: 35px;
        height: 60px;
        cursor: pointer;

        @media (max-width: 480px) {
          height: 50px;
        }
      }

      &__menu {
        display: flex;

        @media (max-width: 480px) {
          flex-direction: column;
          align-items: center;
        }

        &__item {
          color: #fff;
          text-decoration: none;
          font-size: 16px;
          transition: 0.2s ease;

          &:hover {
            text-decoration: underline;
          }

          &:not(:first-child) {
            margin-left: 40px;
            @include tablet {
              margin-left: 10px;
            }
            @media (max-width: 480px) {
              margin-left: 0;
            }
          }
        }
      }
    }

    &__download {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      //opacity: 0;
      //pointer-events: none;
      @media (max-width: 1170px) {
        flex-direction: column;
        align-items: center;
      }

      &__title {
        font-size: 16px;

        @media (max-width: 1024px) {
          text-align: center;
        }
      }

      &__btns {
        &__link:not(:first-child) {
          margin-left: 20px;
        }
      }
    }

    &__support {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      //opacity: 0;
      //pointer-events: none;
      align-items: flex-end;
      @media (max-width: 1170px) {
        flex-direction: column;
        align-items: center;
      }

      &__title {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        text-align: right;
        color: #ffffff;
        @media (max-width: 1024px) {
          text-align: center;
        }
      }

      &__btns {
        &__link {
          display: block;
          text-decoration: none;
          margin-left: 20px;
          padding-top: 23px;
          font-style: normal;
          font-weight: 700;
          font-size: 24px;
          line-height: 26px;
          text-align: right;
          font-feature-settings: "liga" off;
          color: #ffffff;
        }
      }
    }
  }
}
</style>
